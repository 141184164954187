.video-container {
    width: 100vw;
    height: calc(100vh - 90px);
	background:url('../img/crosses.png');
	@include flex(flex, column, center, flex-start);
	margin: 90px 0 0 0;

	@include mobile-xs {
		margin: 70px 0 0;
	}
}

.video-box {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
	z-index: -1;
	
	@include mobile {
		height: 100%;
	}
}

.video-box-src {
	height: 100%;
}

.mfp-content {
    width: 100%;
	height: calc(100% - 90px);
	
	@include tablet {
		height: 100%;
	}
}

.popup-form {
    width: 624px;
	height: 650px;
	@include flex(flex, column, center, flex-start);
    position: absolute;
    margin-left: 50%;
    left: -312px;
    top: 50px;
    background: $main-white;
	box-shadow: $big-shadow;

	@include tablet {
		width: 100vw;
		height: 100vh;
		top: 0px;
		left: 0;
		margin: 0;
		position: fixed;
	}
	
	&__headling {
		font-family: 'Oswald-Bold';
		font-size: 36px;
		color: $main-black;
		margin: 40px 0 60px 0;

		@include mobile {
			font-size: 26px;
		}
	}

	&__small-headling {
		font-family: 'Lato-Regular';
		font-size: 12px;
		color: $main-black;
		letter-spacing: 0.75px;
		margin: 0 0 16px;
		text-transform: uppercase;

		@include mobile {
			font-size: 8px;
		}
	}

	&__wrapper {
		@include flex(flex, row, flex-end, flex-start);
		margin: 0 0 30px;

		@include mobile-xs {
			@include flex(flex, column, flex-start, flex-start);
		}
	}

	&__ticket-name {
		font-family: 'Oswald-Regular';
		font-size: 20px;
		color: $main-black;
		margin: 0 20px 0 0;

		@include mobile {
			font-size: 15px;
		}
	}

	&__ticket-name-box {
		@include mobile {
			width: 100px;
		}
	}

	&__ticket-price {
		font-family: 'Oswald-Regular';
		font-size: 20px;
		color: $main-black;
		margin: 0 20px 0 0;
		text-transform: uppercase;
		
		@include mobile {
			font-size: 15px;
		}
	}

	&__ticket-time {
		font-family: 'Oswald-Light';
		font-size: 14px;
		color: $main-black;
		margin: 0;
		letter-spacing: 0.5px;

		@include mobile {
			font-size: 12px;
		}
	}

	&__ticket-type-box {
		width: 470px;
		@include flex(flex, row, flex-start, space-between);
		border-bottom: 1px dotted $main-black;
		margin: 0 0 16px;

		&_margin-bottom-36 {
			margin-bottom: 36px;
		}

		@include tablet {
			width: 90%;
		}
	}

	&__grey-line {
		width: 100%;
		height: 1px;
		background: #dddddd;
		margin: 0 0 30px;
	}

	&__discount-box {
		width: 470px;
		@include flex(flex, row, flex-end, space-between);

		@include tablet {
			width: 90%;
		}
	}

	&__discount-input {
		width: 120px;
		height: 48px;
		border: 1px solid $main-grey;
		font-family: 'Oswald-Light';
		font-size: 18px;
		color: $main-black;
		outline: none;
		padding: 0 0 0 10px;
		border-radius: 0;

		&:-moz-placeholder {
			color: #9a9a9a;

		}
		&::-webkit-input-placeholder {
			color: #9a9a9a;
		} 
	}

	&__discount-wrapper {
		width: 470px;
		@include flex(flex, row, flex-end, space-between);
		margin: 0 0 30px;

		@include tablet {
			width: 90%;
		}
	}

	&__submit-btn {
		width: 190px;
		height: 50px;
		font-family: 'Lato-Bold';
		font-size: 18px;
		background: $main-gradient;
		color: $main-white;
		border-radius: 0;
		transition: 0.3s;
		border: none;
		outline: none;
		border-radius: 0;
		cursor: pointer;

		&:hover {
			transition: 0.3s;
			animation: color-change-2x 1s linear alternate both;
			box-shadow: $small-shadow;
		}

		@keyframes color-change-2x {
			0% {
			  background: rgb(72, 0, 172);
			}
			100% {
			  background: rgb(120, 9, 184) 0%;
			}
		}

		@include mobile {
			width: 50%;
		}
		
	}
}

.mfp-container {

	@include tablet {
		padding: 0;
	}
}

.mfp-bg {
	@include tablet {
		display: none;
	}
}

.counter-box {
	width: 80px;
	@include flex(flex, row, center, space-between);

	&__minus-btn {
		width: 12px;
		height: 12px;
		cursor: pointer;
		position: relative;

		&:before {
			content: '';
			width: 12px;
			height: 2px;
			position: absolute;
			left: 0;
			top: 6px;
			background: url('../img/minus-btn.svg');
		}	
	}

	&__plus-btn {
		width: 12px;
		height: 12px;
		cursor: pointer;
		position: relative;

		&:before {
			content: '';
			width: 12px;
			height: 12px;
			position: absolute;
			left: 0;
			top: 0;
			background: url('../img/plus-btn.svg');
		}	
	}

	&__quantity {
		width: 40px;
		height: 22px;
		text-align: center;
		border: none;
		font-family: 'Oswald-Regular';
		font-size: 20px;
		outline: none;
		-webkit-appearance: none;

		&:-moz-placeholder {
			color: #9a9a9a;
		}
		&::-webkit-input-placeholder {
			color: #9a9a9a;
		} 
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;

	-webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	-o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
	transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );

}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	-moz-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	-ms-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	-o-transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
	transform: translateY(0) perspective( 600px ) rotateX( 0 ); 
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;

	-webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	-moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	-ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	-o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
	transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); 
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
	opacity: 0;

	-webkit-transition: opacity 0.3s ease-out; 
	-moz-transition: opacity 0.3s ease-out; 
	-o-transition: opacity 0.3s ease-out; 
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}

.main-logo-box {
	width: 600px;
	@include flex(flex, row, center, space-between);
	margin: 40px 0 80px 0;

	@include desktop-xs {
		width: 70%;
	}

	@include tablet {
		width: 90%;
	}

	@include mobile {
		width: 90%;
		margin: 0 0 20px;
	}

	@include mobile-xs {
		display: none;
	}

	&__text {
		font-family: 'Lato-Bold';
		font-size: 14px;
		color: $main-white;
		text-align: center;

		@include mobile {
			font-size: 10px;
		}
	}

	&__image {
		width: 155px;

		@include desktop-xs {
			width: auto;
			height: 30px;
		}

		@include mobile {
			height: 20px;
		}
	}

	&__ips-image {
		@include desktop-xs {
			height: 30px;
		}

		@include mobile {
			height: 20px;
		}
	}
}

.white-bg-text {
	width: 750px;
	background: $main-white;
	padding: 5px 0 9px 0;
	font-family: 'Oswald-Regular';
	font-size: 19px;
	color: #4B00B5;
	text-align: center;
	margin: 0 0 30px;

	@include tablet {
		width: 70%;
	}

	@include mobile {
		width: 236px;
		height: 48px;
		font-size: 14px;
		display: block;
		position: relative;
		
		&::after {
			content: '';
			position: absolute;
			right: -51px;
			top: 0;
			border: 31px solid transparent;	
			border-left: 20px solid $main-white;
		}
		&::before {
			content: '';
			position: absolute;
			left: -51px;
			top: 0;
			border: 31px solid transparent;	
			border-right: 20px solid $main-white;
		}
	}
}

.main-headling {
	font-family: 'Oswald-Bold';
	font-size: 60px;
	color: $main-white;
	text-align: center;
	margin: 0 0 16px;

	@include mobile {
		font-size: 26px;
		margin: 0 0 6px;
	}

	@include mobile-xs {
		font-size: 18px;
		margin: 0 0 6px;
	}
}

.sub-headling {
	font-family: 'Oswald-Bold';
	font-size: 50px;
	color: $main-white;
	text-align: center;
	margin: 0 0 40px;

	@include mobile {
		font-size: 22px;
		margin: 0 0 20px;
	}

	@include mobile-xs {
		font-size: 16px;
		margin: 0 0 6px;
	}


	&__h3 {
		font-family: 'Oswald-Bold';
		font-size: 33px;
		color: #4B00B5;
		text-align: center;
		margin: 0 0 40px;
		text-transform: uppercase;
		background:$main-white;
		padding: 10px;

		@include tablet {
			font-size: 14px;
		}

		@include mobile-xs {
			font-size: 12px;
			margin: 0 0 16px;
		}
	}
}

.text-wrapper {
	width: 750px;
	@include flex(flex, row, center, space-between);
	margin: 0 0 20px;

	@include tablet {
		width: 90%;
		@include flex(flex, column, center, center);
		margin: 0 0 40px;
	}

	@include mobile {
		margin: 0;
	}

	&_agenda-margin {
		margin: 0 0 20px;
	}
}

.sub-text {
	font-family: 'Oswald-Bold';
	font-size: 18px;
	color: $main-white;
	width: 25%;
	text-align: center;

	&_black {
		color: $main-black;
	}

	@include tablet {
		width: 90%;
	}

	@include mobile {
		font-size: 12px;
		margin: 0 0 8px;
	}
}

.main-page__button {
	width: 180px;
	height: 50px;
	background: $main-white;
	font-family: 'Oswald-Bold';
	font-size: 20px;
	color: $main-purp;
	text-align: center;
	line-height: 48px;
	transition: 0.3s;
	outline: none;
	
	&:hover {
		transition: 0.3s;
		box-shadow: $white-shadow;
	}

	@include mobile {
		font-size: 18px;
	}
}

.main-page__agenda-button {
	width: 180px;
	height: 48px;
	background: none;
	font-family: 'Oswald-Bold';
	border: 1px solid $main-white;
	font-size: 20px;
	color: $main-white;
	text-align: center;
	line-height: 48px;
	transition: 0.3s;
	outline: none;
	
	&:hover {
		transition: 0.3s;
		box-shadow: $white-shadow;
	}

	@include mobile {
		font-size: 18px;
	}
}

a.unii-listing-button { display: inline-block; margin-bottom: 0; text-align: center; vertical-align: middle; box-sizing: border-box; text-decoration: none; margin-bottom: 0; font-family: sans-serif; } a.unii-medium { padding: 11px 19px; font-size: 17.5px; -webkit-border-radius: 6px; -moz-border-radius: 6px; border-radius: 6px; line-height: 20px; } a.unii-small { padding: 6px 10px; font-size: 13px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; line-height: 16px; } a.unii-custom { cursor: pointer; -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); color: #fff; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); background-color: #3A66E5; background-repeat: repeat-x; border-color: rgb(233, 23, 93) rgb(233, 23, 93) rgb(255,24,102); border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25); } a.Light { color: #FFF; } a.Light:hover { color: #FFF; } a.Dark { color: #000; } a.Dark:hover { color: #000; }

.btn-wrapper {
	width: 400px;
	@include flex(flex, row, center, space-between);

	@include mobile {
		width: 90%;
		height: 120px;
		@include flex(flex, row, center, space-between);
	}

	@include mobile-xs {
		height: auto;
	}
}

