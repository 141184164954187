.agenda-page {
    width: 100vw;
	@include flex(flex, column, center, flex-start);
    margin: 170px 0 0 0;
    
	@include mobile {
		height: 100%;
    }

    &__main-headling {
        font-family: 'Oswald-Bold';
		font-size: 36px;
        color: $main-black;
        margin: 60px 0 30px;
        align-self: center;

        @include tablet {
            font-size: 24px;
        }

        @include mobile {
            font-size: 18px;
            margin: 20px 0 10px;
        }
    }
    
    &__icon {
        height: 40px;
        fill: $main-black;

        @include tablet-xs {
            width: 110px;
        }
    }

    &_text-color-white {
        background: $main-gradient;
        color: #fff;
    }

    &_text-purp {
        color: $main-purp;
    }

    &_text-black {
        color: $main-black;
        margin: 0 10px;
    }

    &__small-img {
        width: 192px;

        @include tablet {
            margin: 40px 0;
        }

        &_margin-bottom-40 {
            margin: 0 0 40px;
        }
    }

    &__lines {
        width: 100vw;
        height: 160px;
        background: url('../img/lines.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 0 10px;

        @include mobile {
            height: 50px;
        }
    }

    &__wrapper {
        width: 768px;
        @include flex(flex, row, flex-start, space-between);

        @include tablet {
            width: 90%;
            @include flex(flex, column, flex-end, flex-start);
        }
    }

    &__sub-wrapper {
        width: 45%;
        @include flex(flex, row, flex-start, space-around);

        @include tablet {
            width: 95%;
            @include flex(flex, row, flex-start, space-between);
        }

        &_border-bottom {
            border-bottom: 1px solid rgba($color: #979797, $alpha: 0.2);
        }
    }

    &__text {
        font-family: 'Oswald-Regular';
        font-size: 16px;
        color: $main-purp;
        position: relative;

        &:before {
            content: '';
            width: 14px;
            height: 18px;
            position: absolute;
            left: -26px;
            top: 0;
            background: url('../img/arrow-down.svg');
            background-repeat: no-repeat;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &__time {
        font-family: 'Oswald-light';
        font-size: 16px;
        color: $main-purp;

        @include mobile {
            font-size: 14px;
        }
    }

    &__box-wrapper {
        width: 768px;
        @include flex(flex, column, flex-start, flex-start);

        @include tablet {
            width: 90%;
            @include flex(flex, column, center, flex-start);
        }

    }

    &__box {
        width: 100%;
        @include flex(flex, row, flex-start, space-between);
        padding: 40px 0;
        
        &_border-bottom {
            border-bottom: 1px dotted #000;
        }
    }

    &__text-wrapper {
        width: 60%;
        align-self: center;
    }

    &__time-text {
        width: 15%;
        font-family: 'Oswald-Regular';
        font-size: 16px;
        color: $main-black;
        margin: 50px 0 0;
        
        @include tablet {
            font-size: 14px;
        }

        @include mobile {
            width: 16%;
            font-size: 10px;
            margin: 14px 0 0;
        }
    }

    &__image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        
        @include tablet {
            width: 75px;
            height: 75px;
        }

        @include mobile {
            width: 50px;
            height: 50px;
        }
    }

    &__main-text {
        font-family: 'Oswald-Regular';
        font-size: 26px;
        color: $main-black;
        margin: 0;

        &_margin-bottom-12 {
            margin-bottom: 12px;

            @include mobile {
                margin-bottom: 4px;
            }
        }

        @include tablet {
            font-size: 18px;
        }

        @include mobile {
            font-size: 14px;

        }
    }

    &__sub-text {
        font-family: 'Lato-Bold';
        letter-spacing: 1.31px;
        line-height: 22px;
        font-size: 14px;
        color: $main-black;
        margin: 0;
        text-transform: uppercase;

        &_margin-bottom-12 {
            margin-bottom: 12px;
            
            @include mobile {
                margin-bottom: 4px;
            }
        }

        @include tablet {
            font-size: 12px;
        }

        @include mobile {
            font-size: 8px;
            letter-spacing: 0.75px;
            line-height: 22px;
        }
    }

    &__small-text {
        width: 100%;
        font-family: 'Lato-Regular';
        font-size: 14px;
        color: $main-black;
        line-height: 22px;
        margin: 0;

        @include tablet {
            font-size: 10px;
            line-height: 12px;
        }

        @include mobile {
            width: 100%;
            font-size: 8px;
            line-height: 12px;
        }
    }

    &__italic-text {
        width: 100%;
        font-family: 'Lato-Regular';
        font-style: italic;
        font-size: 14px;
        color: $main-black;
        line-height: 22px;
        margin: 0;

        @include tablet {
            font-size: 10px;
            line-height: 12px;
        }

        @include mobile {
            width: 100%;
            font-size: 8px;
            line-height: 12px;
        }
    }

    &__show-more-box {
        width: 100%;

        &_reverse {
            margin: 8px 0 0 0;
            @include flex(flex, column-reverse, flex-start, flex-start);
        }
    }

    &__show-more-btn {
        color: blueviolet;
        font-size: 12px;
        font-family: 'Lato-Bold';
        cursor: pointer;
        padding: 10px 0;
    }

    &__show-more-text {
        display: none;
        width: 100%;
        font-family: 'Lato-Regular';
        font-size: 12px;
        color: $main-black;
        line-height: 20px;
        margin: 0;

        &_active {
            display: block;
        }
    }

    &__more-headling {
        font-family: 'Lato-Bold';
        font-size: 12px;
        color: $main-black;
        line-height: 22px;
    }

    &_margin-top-20 {
        margin-top: 20px;
    }

}