//----mixins----

@mixin desktop-xl {
    @media (max-width: 7000px) {
        @content;
    }
}
  
@mixin desktop-lg {
    @media (max-width: 1920px) {
      @content;
    }
}

@mixin desktop {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin desktop-xs {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin tablet-xs {
    @media (max-width: 540px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin mobile-xs {
    @media (max-width: 375px) {
        @content;
    }
}

@mixin flex ($dspl, $dir, $ai, $jc) {
    display: $dspl;
    flex-direction: $dir;
    align-items: $ai;
    justify-content: $jc;

    @content;
}


// ---- color-vars
$main-gradient: linear-gradient(134deg, #4B00B5 0%, #8630AE 100%);

$big-shadow: 0 2px 70px 0 rgba(0,0,0,0.50);
$small-shadow: 0 4px 8px 2px rgba(0,0,0,0.20);
$white-shadow: 0 0 16px 4px rgba(255,255,255,0.20);

$main-white: #ffffff;

$main-purp: #4B00B5;
$second-purp: #7F2AAE;

$main-black: #000;
$second-black: #3F3F3F;

$main-grey: #979797;

// ----- fonts




// ----- buttons 
