.thanks {
	&__image {
		margin: 200px 0 30px;
	}

	&__headling {
		font-family: 'Oswald-Bold';
		font-size: 36px;
		color: $main-black;
		margin: 0 0 30px;
	}

	&__text {
		font-family: 'Oswald-Regular';
		font-size: 20px;
		line-height: 30px;
		color: $main-black;
		text-align: center;
		margin: 0 0 60px;
	}

	&__link {
		width: 190px;
		height: 50px;
		@include flex(flex, column, center, center);
		font-family: 'Lato-Bold';
		font-size: 18px;
		background: $main-gradient;
		color: $main-white;
		border-radius: 0;
		transition: 0.3s;
		border: none;
		outline: none;
		border-radius: 0;
		cursor: pointer;

		&:hover {
			transition: 0.3s;
			animation: color-change-2x 1s linear alternate both;
			box-shadow: $small-shadow;
		}
	}
}