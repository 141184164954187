html {
    height: 100%;
}

body {
    height: 100%;
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    position: relative;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}

ul,ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
}

.main-box {
    overflow: hidden;
}