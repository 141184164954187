.register-page {
	width: 100%;
	@include flex(flex, column, center, flex-start);
	margin: 0 0 70px;
	
	&__headling {
		font-family: 'Oswald-Bold';
		font-size: 36px;
		color: $main-black;
		margin:  135px 0 70px 0;

		@include mobile {
			margin: 60px 0 30px;
		}
	}

	&__wrapper {
		width: 1024px;
		@include flex(flex, row, flex-start, space-between);
		margin: 0 0 70px;

		@include desktop-xs {
			@include flex(flex, column, flex-start, space-between);
			width: 90%;
		}
	}

	&__text-wrapper {
		@include flex(flex, row, flex-end, flex-start);
		@include mobile-xs {
			@include flex(flex, column, flex-start, flex-start);
		}
	}

	&__column-wrapper {
		@include flex(flex, column, center, flex-start);
	}

	&__left-part {
		width: 60%;
		@include flex(flex, column, flex-start , space-between);

		@include desktop-xs {
			width: 100%;
		}
	}

	&__table{
		width: 100%;
		border-bottom: 1px solid $main-grey;

		&_margin-bottom-40 {
			margin-bottom: 40px;
		}
	}

	&__line {
		height: 50px;
	}

	&__right-part {
		width: 30%;
		padding: 20px 0 0 0;
		@include flex(flex, column, flex-start, space-between);

		@include desktop-xs {
			width: 100%;
		}
	}

	&__ticket-type-box {
		width: 100%;
		@include flex(flex, row, flex-start , space-between);
	}

	&__sub-headling {
		// width: 20%;
		font-family: Lato-Regular;
		font-size: 12px;
		color: $main-black;
		letter-spacing: 0.75px;
		text-transform: uppercase;
		margin: 0 0 16px;
		text-align: center;

		@include mobile {
			font-size: 10px;
		}
		
		&_width-48perc {
			width: 48.9%;
		}

		&_text-align-right {
			text-align: right;
		}

		&_text-align-left {
			text-align: left;
		}
	}

	&__light-ticket-text {
		font-family: 'Oswald-Light';
		font-size: 20px;
		color: $main-black;
		text-align: center;
		line-height: 22px;

		@include mobile {
			font-size: 15px;
		}
	}

	&__ticket-text {
		font-family: 'Oswald-Regular';
		font-size: 20px;
		color: $main-black;
		text-align: center;
		line-height: 22px;

		@include mobile {
			font-size: 15px;
		}

		&_margin-right-20 {
			margin-right: 20px;

			@include mobile-xs {
				margin: 0;
			}
		}

		&_text-align-right {
			text-align: right;
		}

		&_text-align-left {
			text-align: left;
		}

		&_width-20perc {
			width: 20%;

			@include mobile-xs {
				width: 30%;
			}
		}
	}
	
	&__ticket-time {
		font-family: 'Oswald-Light';
		font-size: 14px;
		color: $main-black; 
		margin: 20px 0;
		
		@include mobile {
			font-size: 12px;
		}
		
		@include mobile-xs {
			margin: 5px 0;
		}
	}

	&__card-form {
		width: 100%;
		@include flex(flex, row, flex-start, space-between);

		@include tablet {
			@include flex(flex, column, flex-start, space-between);
		}
		
	}

	&__card-form-wrapper {
		@include flex(flex, column, flex-start, flex-start);
		
		&_margin-bottom-26 {
			margin-bottom: 45px;
		}

		@include mobile {
			width: calc(100% - 20px);
			margin: 0 0 26px;

		}
	}

	&__card-input {
		width: 300px;
		height: 50px;
		border: 2px solid $main-grey;
		padding: 0 0 0 20px;
		font-size: 18px;
		font-family: 'Oswald-Light';
		outline: none;

		&:-moz-placeholder {
			color: #9a9a9a;
		}
		&::-webkit-input-placeholder {
			color: #9a9a9a;
		} 

		@include tablet-xs {
			width: 100%;
		}
	}

	&__label {
		
		font-family: 'Lato-Regular';
		font-size: 12px;
		color: $main-black;
		letter-spacing: 0.75px;
		text-transform: uppercase;
		margin: 0 0 10px;

		@include mobile {
			font-size: 10px;
		}
	}
}

#exdate {
	width: 95px;
}

#cvc {
	width: 95px;
}