.header {
    width: 100%;
    height: 90px;
    @include flex(flex, row, center, center);
    background:url('../img/crosses.png');
    position: fixed;
    top: 0;
    left: 0;

    @include mobile {
        height: 60px;
    }
        
    &__background {
        @include desktop-xl {
            background-size: 100%;
        }
    
        @include desktop-lg {
            background-size: auto;
        }
    
        background: url('../img/header-bg.png');
        background-repeat: no-repeat;
    }

    &__wrapper {
        width: 1024px;
        @include flex(flex, row, center, space-between);

        @include desktop-xs {
            width: 90%;
        }
    }

    &__navigation {
        width: 350px;
        @include flex(flex, row, center, space-between);

        @include mobile {
            width: 100%;
        }
    }

    &__link {
        height: 100%;
        font-family: 'Oswald-Bold';
        font-size: 20px;
        color: $main-white;
        opacity: 0.6;
        text-align: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            opacity: 0.8;
        }

        &_active {
            opacity: 1;
            
            &:hover {
                opacity: 1;
            }
        }
    }

    &__button {
        width: 140px;
        height: 50px;
        background: $main-white;
        border: none;
        outline: none;
        border-radius: 0;
        font-family: 'Oswald-Bold';
        font-size: 20px;
        line-height: 50px;
        color: $main-purp;
        text-align: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            transition: 0.3s;
            box-shadow: $white-shadow;
        }

        @include mobile {
            height: 35px;
            line-height: 32px;
        }
    }

}

.social-media-box {
    width: 200px;
    @include flex(flex, row, center, space-between);

    @include tablet-xs {
        display: none;
    }

    &__icon {
        opacity: 0.7;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            opacity: 1;
        }
    }
}

.mobile-social-media-box {
    display: none;

    @include mobile {
        @include flex(flex, row, center, center);
        margin: 30px 0;
        width: 200px;
    }

    @include mobile-xs {
        display: none;
    }
}